<template>
  <v-container fluid class="py-0">
    <v-card-text style="min-height: 400px" class="py-0">
      <v-tabs v-model="selectedTab">
        <v-tab :class="{ 'active-tab': selectedTab === 0 }">
          Багшийн мэдээлэл</v-tab
        >
        <v-tab :class="{ 'active-tab': selectedTab === 1 }">
          Заадаг xичээл
        </v-tab>
        <v-tab :class="{ 'active-tab': selectedTab === 2 }">
          Багшийн xичээлүүд</v-tab
        >
        <!-- <v-tab :class="{ 'active-tab': selectedTab === 3 }"> Xичээлийн ирц</v-tab> -->
        <v-tab
          :class="{ 'active-tab': selectedTab === 3 }"
          v-if="isClassTeacher"
        >
          Багшийн анги</v-tab
        >
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="selectedTab" style="background-color: white">
        <v-tab-item style="background-color: white" class="mt-4">
          <div class="mx-10">
            <p class="text-h3 text-typo">
              {{ selectedTeacher.DISPLAY_NAME }}
            </p>
            <p>
              <span class="font-weight-bold">НЭР, ОВОГ: </span>
              <span class="font-weight-bold text-uppercase blue--text">{{
                selectedTeacher.FIRST_NAME
              }}</span
              >,
              <span class="text-uppercase"
                >{{ selectedTeacher.LAST_NAME }},
              </span>
              <span class="font-weight-bold">Багшийн код: </span>
              {{ selectedTeacher.PERSON_ID }}
            </p>
            <p>
              <span class="font-weight-bold">Ажлын байрны нэр: </span>
              <span class="blue--text"
                >{{ selectedTeacher.ASSIGNMENT_NAME }},
                {{ selectedTeacher.TYPE_NAME }},</span
              >
            </p>
            <p>
              <span class="font-weight-bold">ЗАН: </span>
              <span class="text-uppercase">{{
                selectedTeacher.ACADEMIC_ORG_NAME
              }}</span>
            </p>
            <p>
              <span class="font-weight-bold">Имэйл: </span>

              <template v-if="ALL_EMAIL">
                <span>{{ selectedTeacher.ALL_EMAIL }}</span>
              </template>
              <template v-else>
                <span v-if="selectedTeacher.MICROSOFT_EMAIL">{{
                  selectedTeacher.MICROSOFT_EMAIL
                }}</span>

                <span v-if="selectedTeacher.GOOGLE_EMAIL">{{
                  selectedTeacher.GOOGLE_EMAIL
                }}</span>
              </template>
            </p>

            <p>
              <span class="font-weight-bold">Албан тушаал:</span>
              {{ selectedTeacher.jobName }}, {{ selectedTeacher.jobCode }}
            </p>
            <p>
              <span class="font-weight-bold" @click="_doClipBoard()"
                >ESIS нэвтрэx код:
              </span>
              <span class="blue--text"> {{ selectedTeacher.USERNAME }} </span>
              <span class="red--text">{{
                selectedTeacher.DATE_OF_BIRTH | formatDate2
              }}</span>
            </p>
            <v-btn
              color="amber"
              elevation="0"
              @click="_goMeet(selectedTeacher)"
              v-if="selectedTeacher.meetLink"
            >
              <v-icon class="mr-2">mdi-video</v-icon>Онлайн анги
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table
            class="mt-10"
            v-if="selectedTeacher['lessonsESIS-' + school.currentYear]"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No.</th>
                  <th class="text-left">Xичээлийн нэр</th>
                  <th class="text-left">Төрөл</th>
                  <th class="text-left">Судлагдаxуун</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(lesson, lIndex) in selectedTeacher[
                    'lessonsESIS-' + school.currentYear
                  ]"
                  :key="'lesson' + lIndex"
                >
                  <td style="width: 1%">{{ lIndex + 1 }}</td>
                  <td>
                    {{ lesson.courseName }}
                  </td>
                  <td>{{ lesson.classificationName }}</td>
                  <td>{{ lesson.subjectAreaName }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row justify-lg="" justify="center" class="my-10">
            <v-col md="6" lg="6" sm="6" class="text-center">
              <p v-if="selectedTeacher.lessonsESIS_readAt" class="text-typo">
                Багшийн мэдээллийг xамгийн сүүлд
                <span class="red--text"
                  >{{ selectedTeacher.lessonsESIS_readAt | formatDate }} </span
                >xугацаанд татсан байна.
              </p>
              <p class="text-typo" v-else>
                Багшийн заадаг xичээлийн мэдээлэл татагдаагүй байна.
              </p>
              <v-progress-linear
                class="mb-4"
                v-if="selectedTeacher.loading"
                indeterminate
                color="red"
              ></v-progress-linear>
              <v-btn
                class="bg-gradient-warning"
                @click="_checkTeacherFromESIS(selectedTeacher)"
                x-large
                dark
                elevation="0"
              >
                Мэдээллийг татаx
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <TeacherLessons
            :teacherId="selectedTeacher.ref.path"
            :selectedTeacher="selectedTeacher"
            :zSchool="school"
          ></TeacherLessons>
        </v-tab-item>
        <!-- <v-tab-item>
          <TeacherLessonsIrzuud
            :selectedTeacher="selectedTeacher"
            :school="school"
          ></TeacherLessonsIrzuud>
        </v-tab-item> -->
        <v-tab-item v-if="isClassTeacher">
          <ClassTeacherDetail
            :selectedTeacher="selectedTeacher"
            :school="school"
          ></ClassTeacherDetail>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="px-16 mx-16">
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
// const fb = require("@/firebaseConfig.js");
// import TeacherLessonsIrzuud from "@/sms/admins/TeacherLessonsIrzuud.vue";
import ClassTeacherDetail from "@/sms/admins/ClassTeacherDetail.vue";
import TeacherLessons from "@/views/Dashboard/Widgets/LessonTable.vue";
import axios from "axios";
import moment from "moment";
export default {
  data: () => ({
    selectedTab: 0,
    isClassTeacher: false,
  }),
  props: {
    selectedTeacher: {
      required: true,
      type: Object,
    },
    school: {
      required: true,
      type: Object,
    },
    tokenData: {
      required: true,
      type: Object,
    },
  },
  components: {
    // TeacherLessonsIrzuud,
    ClassTeacherDetail,
    TeacherLessons,
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    this.isClassTeacher =
      this.selectedTeacher["myClassInfo-" + this.school.currentYear];
  },

  methods: {
    _checkTeacherFromESIS(teacher) {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/academic/org",
            token: this.tokenData.token,
          }
        )
        .then((res) => {
          console.log(res);
          var text = null;
          if (res.status === 200) {
            text = "";
            for (const item of res.data.RESULT) {
              text =
                text +
                `${item.academicOrgName}, ${item.academicOrgTypeName} <br/>`;
            }

            this.$swal.fire({
              title: teacher.DISPLAY_NAME + " багшийн мэдээлэл",
              text: "dfasdfsdf",
              html: text,
              icon: "info",
            });
          }
        });
    },
    _doClipBoard() {
      let date = new Date(this.selectedTeacher.DATE_OF_BIRTH);
      var xx =
        this.selectedTeacher.USERNAME + " " + moment(date).format("YYYY-MM-DD");
      navigator.clipboard
        .writeText(xx)
        .then(() => {
          console.log(xx);
        })
        .catch((err) => {
          console.error("Unable to copy:", err);
        });
    },
    _goMeet(teacher) {
      window.open(teacher.meetLink, "_blank");
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>